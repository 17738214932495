class setDonateType {
  constructor() {
    if (!this.setVars()) return
    this.setEvents()
  }

  setVars() {
    this.container = document.querySelector('[data-formContent]')
    if (!this.container) return false

    this.wrap = this.container.firstElementChild

    this.btn = this.container.querySelectorAll('[data-btn]')
    if (!this.btn.length) return false

    this.form = this.container.querySelectorAll('[data-cform]')
    if (!this.form.length) return false

    return true
  }

  setEvents() {
    each(this.btn, (key, val) => {
      val.addEventListener('click', (e) => {
        val.parentNode.style.display = 'none'
        this.container.classList.add('dotLine_')
        this.form[key].style.display = ''
      })
    })
  }
}

export default setDonateType
