import '../sass/core.scss';
import '../sass/framework/hd.scss';

import "./global";
import {lazyCSS} from "./class/lazyCSS";
import {lazyImg} from "./class/lazyImg";
import cardToken from "./class/cardToken";
import api from "./class/api";
import inputCalendar from "./class/inputCalendar";
import exportPdf from "./class/exportPdf";
import setDonateType from "./class/setDonateType";

const mod = {};

// ======== load core =========
mod.core = () => {
  window.csrf = document.head.querySelector('meta[name="_token"]').content || '';
  mod.css = new lazyCSS();
  mod.lazy = new lazyImg('[data-url]');
  let scLinks = document.body.querySelectorAll('[data-sc]');
  scLinks.length && each(scLinks, (key, val) => {
    val.addEventListener('click', (e) => {
      e.preventDefault()
      sc(val.dataset.sc, 100, val.href);
    })
  })

  JSON.stringify()
  new exportPdf()
  new cardToken()
  new api()
  new setDonateType()
  new inputCalendar();
  (!localStorage.getItem('cookie') && window.location.href.indexOf('manager') <= 0) && render(document.body, `<div style="padding:2em;font-size:15px;line-height:22px;border-bottom: 1px solid #D3D0D0; text-align: center; position: fixed; bottom: 0px; background-color: #d8a499; width: 100%; z-index: 999; opacity: 0.97; font-family: Arial;">
Administratorem Państwa danych osobowych jest Stowarzyszenie na Rzecz Wspierania Aktywności Seniorów „AS”
Informujemy, iż nasza strona korzysta z plików cookies. Cookies” to pliki, które zapewniają prawidłowe funkcjonowanie serwisu oraz pozwalają nam gromadzić informacje związane z Tobą i Twoim urządzeniem w momencie, gdy odwiedzasz naszą witrynę internetową. Pliki cookie pozwalają nam ustalić m.in. liczbę użytkowników odwiedzających naszą stronę i częstotliwość tych odwiedzin a także sposób korzystania przez Ciebie z naszej strony internetowej. Więcej informacji dot. przetwarzania Państwa danych osobowych i przysługujących Państwu z tego tytułu praw, znajdziecie Państwo w naszej <a href="assets/files/polityka-prywatnosci-i-cookies-zawieszony-obiad.pdf" target="_blank">Polityce prywatności.</a><button id="accept-cookies-checkbox" name="accept-cookies" style="background-color:#ffffff; font-size: 16px; padding: 5px 10px; color: #000; border:0;border-radius: 7px; -moz-border-radius: 7px; -webkit-border-radius: 7px; display: inline-block; margin-left: 10px; text-decoration: none; cursor: pointer;" onclick="del(this.parentNode);localStorage.setItem('cookie', 1)">Akceptuję</button> <a href="assets/files/polityka-prywatnosci-i-cookies-zawieszony-obiad.pdf" style="background-color:#ffffff; font-size: 16px; padding: 5px 10px; color: #000; border-radius: 7px; -moz-border-radius: 7px; -webkit-border-radius: 7px; display: inline-block; margin-left: 10px; text-decoration: none; cursor: pointer;" target="_blank">Polityka prywatności</a></div>`);
  addCSS('https://use.typekit.net/gof3kdc.css')
};

!is_observer() ? addJS('js/polyfill.js', {
  cb: mod.core
}) : document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', mod.core) : mod.core();
