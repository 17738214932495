class exportPdf {
  constructor() {
    if (!this.setVars()) return
    this.setEvents()
  }

  setVars() {
    this.pdf = document.body.querySelectorAll('[data-pdf]')
    return this.pdf.length
  }

  setEvents() {
    let element, opt
    each(this.pdf, (key, val) => {
      val.addEventListener('click', (e) => {
        e.preventDefault()
        element = document.body.querySelector(val.dataset.pdf)
        if (!element) return
        addJS('https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js', {
          id: 'pdf',
          cb: () => {
            opt = {
              margin: 2,
              filename: val.dataset.filename || 'file.pdf',
              image: {type: 'jpeg', quality: 0.95},
              pagebreak: { after: ['.break'], avoid: 'img' },
              html2canvas: {scale: 2},
              enableLinks: true,
              jsPDF: {unit: 'mm', format: 'a4', orientation: 'p'}
            }
            window.scrollTo(0, 0)
            html2pdf().set(opt).from(element).save();
          }
        })
      })
    })
  }
}

export default exportPdf
