export function _request(url, data, cfg = {}) {
  let controller = new AbortController(), obj = Object.assign({
    signal: controller.signal,
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    //'Content-Type': 'application/json', //multipart/form-data, application/json, application/x-www-form-urlencoded
    headers: {
      'Accept': 'text/csv, application/json, application/xml, text/plain, text/html, application/pdf, image/jpeg, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'Content-Type': typeof cfg.type !== 'undefined' ? cfg.type : 'application/json'
    },
    body: data,
    cb: null,
  }, cfg);

  obj.type && obj.type.indexOf('multipart') > -1 && delete obj.headers;

  fetch(url, obj).then(response => {
    response.status !== 200 && controller.abort();
    typeof obj.cb === 'function' && obj.cb(response);
  });
}
