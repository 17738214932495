//import api from "./api";

class cardToken {
  constructor() {
    if (!this.setVars()) return
    addJS('https://secure.payu.com/javascript/sdk', {
      cb: () => {
        this.setEvents();
      }
    })
  }

  setVars() {
    this.token_btn = document.body.querySelector('[data-card]');
    return this.token_btn;
  }

  setEvents() {
    this.setFingerPrint();
  }

  initSecureForm() {
    let optionsForms = {
      cardIcon: true,
      style: {
        basic: {
          fontSize: '24px'
        }
      },
      placeholder: {
        number: '',
        date: 'MM/YY',
        cvv: ''
      },
      lang: 'pl'
    }

    let renderError = (element, errors) => {
      element.className = 'response-error';
      let messages = [];
      errors.forEach(function (error) {
        messages.push(error.message);
      });
      element.textContent = messages.join(', ');
    }

    let renderSuccess = (element, result) => {
      responseError.textContent = ''
      element.className = 'response-success';
      element.value = result.body.token;
      let submitSecureForm = document.getElementById('submitSecureForm')
      if (submitSecureForm) submitSecureForm.click()
    }

    //inicjalizacja SDK poprzez podanie POS ID oraz utworzenie obiektu secureForms
    let payuSdkForms = PayU('4303479');
    let secureForms = payuSdkForms.secureForms();

    //utworzenie formularzy podając ich typ oraz opcje
    let cardNumber = secureForms.add('number', optionsForms);
    let cardDate = secureForms.add('date', optionsForms);
    let cardCvv = secureForms.add('cvv', optionsForms);

    //renderowanie formularzy
    cardNumber.render('#payu-card-number');
    cardDate.render('#payu-card-date');
    cardCvv.render('#payu-card-cvv');

    let tokenizeButton = document.getElementById('tokenizeButton');
    let responseElement = document.getElementById('responseTokenize');
    let responseError = document.getElementById('responseError');

    tokenizeButton.addEventListener('click', () => {
      responseElement.value = '';

      try {
        //tokenizacja karty (komunikacja z serwerem PayU)
        payuSdkForms.tokenize(this.token_btn.dataset.card).then((result) => { // przykład dla tokenu typu SINGLE
          result.status === 'SUCCESS'
            ? renderSuccess(responseElement, result) //tutaj wstaw przekazanie tokena do back-endu
            : renderError(responseError, result.error.messages); //sprawdź typ błędu oraz komunikaty i wyświetl odpowiednią informację użytkownikowi
        })
      } catch (e) {
        console.log(e); // błędy techniczne
      }
    });
  }

  setFingerPrint() {
    addJS('https://openfpcdn.io/fingerprintjs/v4/iife.min.js', {
      cb: () => {
        console.log('init fingerprint')
        let fpPromise = FingerprintJS.load()
        fpPromise.then(fp => fp.get()).then(result => {
            this.visitorId = result.visitorId
            let fingerPrintInput = document.body.querySelector('input[name="deviceFingerPrint"]')
            if (fingerPrintInput) fingerPrintInput.value = result.visitorId
            this.initSecureForm()
          })
      }
    })
  }
}

export default cardToken
